/*
 * Project: ecrf-front
 * Author: Dominik Obłoza
 * User: @dominik.obloza
 * Date: 14.02.2022
 * Time: 10:44
 */

import {
    StyledDashboardQueriesDetails,
    StyledDashboardQueriesDetailsContent,
    StyledDashboardQueriesDetailsDate,
    StyledDashboardQueriesDetailsTitle,
    StyledDashboardQueriesDetailsTop,
    StyledDashboardQueriesTableCell,
    StyledDashboardQueriesTableCellIcon,
    StyledDashboardQueriesTableCellIcons,
    StyledDashboardQueriesTableRow
} from "./DashboardQueries.styled";
import React, {useEffect, useState} from 'react';
import DateTime from "../../../../core/helpers/date/DateTime";
import CallMadeIcon from '@mui/icons-material/CallMade';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Box, CircularProgress, Collapse, Tooltip } from "@mui/material";
import QueryStatus from "./QueryStatus";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useNavigate} from "react-router-dom";
import { ROUTES_ECRF } from "../../../../commons/constants/paths";
import { getSectionId } from "../../../ecrf/redux/ecrfSlice";
import { useAppDispatch } from "../../../../commons/store/hooks";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import axiosSecureInstance from "../../../../commons/axios/axiosSecureInstance";
import { DATETIME_FORMAT } from "../../../../commons/constants/env";
import { useTranslation } from "react-i18next";

export const QueryRow: React.FC<any> = (props) => {
    const [status, setStatus] = useState<null | string>(null)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    /**
     * This function is used to set status of element
     *
     * verified - element has status 1
     * new - query has not any answers
     * answered - query has at least one answer
     * set - query has not any answers (set by current user)
     */
    const setRowStatus = () => {
        if (props.data?._embedded?.element?.status === 1) {
            setStatus('verified')
            return 'verified'
        }

        if (props.data?.countChildren > 0) {
            setStatus('answered')
            return 'answered'
        }

        if (props.user?.firstname + " " + props.user?.lastname === props.data?._embedded?.owner?.fullName) {
            setStatus('set')
            return 'set'
        } else {
            setStatus('new')
            return 'new'
        }
    }

    /**
     * This function is used to redirect to ecrf if is possible
     * @param data
     */
    const redirectToEcrf = (data: any) => {
        if (data?._embedded?.section?.id && data._embedded?.element?._embedded?.ecrf?.id) {
            navigate(ROUTES_ECRF.DETAILS(data._embedded?.element?._embedded?.ecrf?.id || ""))
            dispatch(getSectionId({
                    section: data?._embedded?.section?.id,
                    element: data?._embedded?.element?._embedded?.element?.id,
                })
            )
        }
    }

    const redirectButtonStatus = (data: any) => !(!data?._embedded?.section?.id || !data._embedded?.element?._embedded?.ecrf?.id);

    /**
     * This function is used to open query panel
     */
    const handleOpenPanel = () => {
        if (openPanelButtonStatus()) {
            props.openQueryPanel();
            props.selectRowData(props.data.id, props.data._embedded?.element.id, props.data._embedded?.element?._embedded?.element?.title)
        }
    }

    const openPanelButtonStatus = () => {
        return !!(props.data?.id && props.data._embedded?.element?.id && props.data._embedded?.element?._embedded?.element?.name);
    }

    useEffect(() => {
        setRowStatus()
    }, [])

    /* Toggle show details state */
    const [showDetails, setShowDetails] = useState(false)
    /* This state is holding details about query (children list) */
    const [details, setDetails] = useState<any>([])
    /* Loading details animation */
    const [isDetailsLoading, setIsDetailsLoading] = useState(false)

    /**
     * This function is used to get list of answers
     * @param id
     */
    const getDetails = async (id: string) => {
        if (props.data?.countChildren > 0) {
            setShowDetails(!showDetails)
            if (!showDetails) {
                try {
                    setIsDetailsLoading(true)
                    const response = await axiosSecureInstance.get(`/api/comments/${id}/childrens?order[createdAt]=asc`)
                    setDetails(response.data)
                    setIsDetailsLoading(false)
                } catch (err: any) {
                    alert(err?.detail)
                    setIsDetailsLoading(false)
                }
            }
        }
    }

    return (
        <>
            <StyledDashboardQueriesTableRow status={status}>
                <StyledDashboardQueriesTableCell> {/* Query number */}
                    {props.idx || '-'}.
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Warning icon */}
                    {status === 'new' &&
                        <Tooltip title={t('new-query')} arrow>
                            <Box display={'grid'} justifyContent={'center'}>
                                <ErrorOutlineOutlinedIcon style={{color: '#CA8A04'}}/>
                            </Box>
                        </Tooltip>
                    }
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Screening number */}
                    {props.data?._embedded?.element?._embedded?.ecrf?.randomId || '-'}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Date of created */}
                    {DateTime.toIso(props.data?.createdAt, DATETIME_FORMAT)}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Owner name */}
                    {props.data?._embedded?.owner?.fullName || "-"}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Recipient name */}
                    {props.data?._embedded?.recipient?.fullName || "-"}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Name of section */}
                    {props.data?._embedded?.section?.name || "-"}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Title of element */}
                    {props.data?._embedded?.element?._embedded?.element?.title || '-'}
                </StyledDashboardQueriesTableCell>

                <Tooltip style={{cursor: 'help'}}
                    title={props.data?._embedded?.element?.data && Array.isArray(props.data?._embedded?.element?.data) ?
                        props.data?._embedded?.element?.data.map((data: any) =>
                            <Box>{DateTime.isDateTime(data) ? DateTime.toIso(data, DATETIME_FORMAT) : data}</Box>) || '-'
                        : '-'}>

                    <StyledDashboardQueriesTableCell> {/* Value of element */}
                        <Box whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} maxWidth={'100px'}>
                            {DateTime.isDateTime(props.data?._embedded?.element?.data[0]) ?
                                DateTime.toIso(props.data?._embedded?.element?.data[0], DATETIME_FORMAT)
                                : (props.data?._embedded?.element?.data[0] || '-')}
                        </Box>
                    </StyledDashboardQueriesTableCell>
                </Tooltip>

                <StyledDashboardQueriesTableCell onClick={() => getDetails(props.data?.id)}
                    style={{
                        cursor: props.data?.countChildren > 0 ? 'pointer' : 'unset',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                    }}> {/* Content of query */}
                    {props.data?.content || "-"}
                    {props.data?.countChildren > 0 &&
                        <>
                            {showDetails ? <ExpandLess/> : <ExpandMore/>}
                        </>
                    }
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCell> {/* Local status */}
                    {status && <QueryStatus variant={status}/>}
                </StyledDashboardQueriesTableCell>

                <StyledDashboardQueriesTableCellIcons> {/* Buttons to redirect and open query panel */}
                    <Tooltip title={redirectButtonStatus(props.data) ? t('go-to-ecrf') : t('no-access')} arrow>
                        <StyledDashboardQueriesTableCellIcon status={redirectButtonStatus(props.data)}
                            onClick={() => redirectToEcrf(props.data)}>
                            <CallMadeIcon style={{color: '#243A4E'}}/>
                        </StyledDashboardQueriesTableCellIcon>
                    </Tooltip>

                    <Tooltip title={t('add-reply')} arrow>
                        <StyledDashboardQueriesTableCellIcon status={openPanelButtonStatus()}
                            onClick={() => handleOpenPanel()}>
                            <QuestionAnswerOutlinedIcon style={{color: '#243A4E'}}/>
                        </StyledDashboardQueriesTableCellIcon>
                    </Tooltip>
                </StyledDashboardQueriesTableCellIcons>
            </StyledDashboardQueriesTableRow>

            {/* <--- Display query answers ---> */}
            <Collapse in={showDetails} mountOnEnter unmountOnExit>
                <Box border={`1px solid #e7e7e7`} borderTop={'unset'} p={3}
                    borderRadius={'0px 0px 10px 10px'} pl={36}>

                    <StyledDashboardQueriesDetailsTop>
                        <StyledDashboardQueriesDetailsTitle>
                            {props.data?._embedded?.owner?.fullName || "-"}
                        </StyledDashboardQueriesDetailsTitle>
                        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                            <StyledDashboardQueriesDetailsContent>
                                {props.data?.content || "-"}
                            </StyledDashboardQueriesDetailsContent>
                            <StyledDashboardQueriesDetailsDate>
                                {DateTime.toIso(props.data?.createdAt, DATETIME_FORMAT)}
                            </StyledDashboardQueriesDetailsDate>
                        </Box>
                    </StyledDashboardQueriesDetailsTop>

                    {[].slice.call(details).map((child: any) =>
                        <StyledDashboardQueriesDetails>
                            <StyledDashboardQueriesDetailsTitle>
                                {child?.owner?.fullName}
                            </StyledDashboardQueriesDetailsTitle>
                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <StyledDashboardQueriesDetailsContent>
                                    {child?.content}
                                </StyledDashboardQueriesDetailsContent>
                                <StyledDashboardQueriesDetailsDate>
                                    {DateTime.toIso(child?.createdAt, DATETIME_FORMAT)}
                                </StyledDashboardQueriesDetailsDate>
                            </Box>
                        </StyledDashboardQueriesDetails>
                    )}

                    {isDetailsLoading && <CircularProgress/>}
                </Box>

            </Collapse>
        </>
    );
}

export default QueryRow;
